import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Link } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import useGET from "../http/useGET";
import { AudioHandler } from "../hooks/AudioHandler";
import { ImageHandler } from "../hooks/ImageHandler";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import NaturmarketImage from "../img/Naturmarket.png";
import FormControl from "@mui/material/FormControl";
import defaultTheme from "../theme";
import {getToken} from "../http/tokenGET";

export default function AdminQuestionsList() {
    const { data: questions } = useGET(`${process.env.REACT_APP_API_URL}/questions`);
    const [selectedItem, setSelectedItem] = useState("");
    const [questionList, setQuestionList] = useState([]);

    const [questionText, setQuestionText] = useState("");
    const [answer1, setAnswer1] = useState("");
    const [answer2, setAnswer2] = useState("");
    const [answer3, setAnswer3] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    const filteredQuestions = (questionList)?.filter(
        (s) =>
            (!searchTerm || s.question.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const [selectedSpecies, setSelectedSpecies] = useState("");

    const {
        handleFileChange: handleFileChangeQ,
        imageFileEncoded: imageFileEncodedQ,
        setImageFileEncoded: setImageFileEncodedQ,
    } = ImageHandler();
    const { handleFileChange: handleFileChange1, imageFileEncoded: imageFileEncoded1 } = ImageHandler();
    const { handleFileChange: handleFileChange2, imageFileEncoded: imageFileEncoded2 } = ImageHandler();
    const { handleFileChange: handleFileChange3, imageFileEncoded: imageFileEncoded3 } = ImageHandler();

    const { AudioUpload, audioFile, setAudioFile } = AudioHandler();

    const { data: species } = useGET(`${process.env.REACT_APP_API_URL}/species`);

    useEffect(() => {
        setQuestionList(questions);
    }, [questions]);

    useEffect(() => {
        const fetchQuestionsList = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/questions`);
                const updatedQuestionsList = await response.json();
                setQuestionList(updatedQuestionsList);
            } catch (error) {
                console.error("Error fetching questions list: ", error);
            }
        };

        fetchQuestionsList();
    }, []);

    const handleClick = useCallback((selected) => {
        setSelectedItem(selected);
        setQuestionText(selected.question);
        setImageFileEncodedQ(selected.image);
        setAnswer1(selected.correct_answer);
        setAnswer2(selected.wrong_answer1);
        setAnswer3(selected.wrong_answer2);
        setSelectedSpecies(selected.species_id);
    }, [setImageFileEncodedQ]);

    useEffect(() => {
        if (selectedItem) handleClick(selectedItem);
    }, [selectedItem, handleClick]);

    useEffect(() => {
        if (imageFileEncoded1 != null) {
            //setAnswer1("image_url:" + imageFileEncoded1);
        }
    }, [imageFileEncoded1]);

    useEffect(() => {
        if (imageFileEncoded2 != null) {
            //setAnswer2("image_url:" + imageFileEncoded2);
        }
    }, [imageFileEncoded2]);

    useEffect(() => {
        if (imageFileEncoded3 != null) {
            //setAnswer3("image_url:" + imageFileEncoded3);
        }
    }, [imageFileEncoded3]);

    function handleCheck(id) {
        setSelectedSpecies(id);
    }

    const handleRemoveImage = (imageName) => {
        if (imageName === "answer 1") {
            setAnswer1("");
        } else if (imageName === "answer 2") {
            setAnswer2("");
        } else if (imageName === "answer 3") {
            setAnswer3("");
        }
    };

    const handleSave = async () => {
        const updatedQuestion = {
            correct_answer: answer1,
            question: questionText,
            wrong_answer1: answer2,
            wrong_answer2: answer3,
            image: null,
            audio: null,
            image_path: null,
            species_id: selectedSpecies,
        };

        const url = selectedItem
    ? `${process.env.REACT_APP_API_URL}/questions/${selectedItem.id}` 
    : `${process.env.REACT_APP_API_URL}/questions`;

        const method = selectedItem ? "PUT" : "POST";

        try {
            const token = await getToken();
            const response = await fetch(url, {
                method,
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-CSRF-TOKEN': token
                },
                body: JSON.stringify(updatedQuestion),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status} - ${response.statusText}`);
            }

            const data = await response.json();

            if (method === "POST" && data && data.question) {
                setQuestionList(([...questionList, data.question]))
            }else if (method === "PUT" && data && data.question){
                setQuestionList(questionList.map(question =>
                    question.id === data.question.id
                        ? { ...question, ...data.question }
                        : question
                ));
            }

            handleAdd();
        } catch (error) {
            console.error("Error adding new question:", error);
        }
    };

    const handleDeleteQuestion = async () => {
        try {
            const token = await getToken();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/questions/${selectedItem.id}`,{
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-CSRF-TOKEN': token
                }
            });

            if (response.status === 200) {
                setQuestionList(prevList => prevList.filter(question => question.id !== selectedItem.id));
                handleAdd();
            }
        } catch (error) {
            console.error("Error deleting question:", error);
        }
    };

    const handleAdd = () => {
        setQuestionText("");
        setSelectedSpecies("");
        setAnswer1("");
        setAnswer2("");
        setAnswer3("");
        setSelectedItem(null);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <AppBar position="relative" style={{ backgroundColor: "#8EA534", padding: "10px" }}>
                <Toolbar>
                    <div style={{ display: "flex", alignItems: "center", padding: "1rem" }}>
                        <img
                            src={NaturmarketImage}
                            alt="Naturmärket"
                            style={{ width: "100px", height: "100px", marginRight: "1rem", borderRadius: "50%" }}
                        />
                        <Typography variant="h3" style={{ color: "white", fontSize: "1.5rem"}}>
                            Naturborgarmärket
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Link
                to="/adminHome"
                style={{ textDecoration: "none", position: "absolute", top: 150, left: 0, margin: "16px" }}
            >
                <Button variant="contained" size="large" color="success">
                    Tillbaka
                </Button>
            </Link>
            <Box
                bgcolor="#fff"
                sx={{
                    py: 6,
                }}
            >
                <Container maxWidth="lg">
                    <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        gutterBottom
                        sx={{
                            fontFamily: ["Arial"],
                            letterSpacing: 2,
                            m: 1,
                            pt: 4,
                            color: "primary",
                        }}
                    >
                        Redigera Quizfrågor
                    </Typography>
                </Container>
            </Box>
            <Container maxWidth="lg" sx={{ mb: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper
                            sx={{
                                overflow: "auto",
                                width: 1,
                                height: 590,
                                backgroundColor: "white",
                                textAlign: "Center",
                                borderColor: "#4CAF50",
                                borderStyle: "solid",
                                borderRadius: "10px",
                                padding: 2,
                                marginLeft: -2,
                            }}
                        >
                            <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                    py: 2,
                                    fontFamily: ["Arial"],
                                    letterSpacing: 2,
                                    m: 1,
                                }}
                            >
                                Frågelista
                            </Typography>

                            {/* Sökfält */}
                            <TextField
                                id="search-field"
                                label="Sök art"
                                type="search"
                                variant="outlined"
                                fullWidth
                                sx={{
                                    marginBottom: "25px",
                                    backgroundColor: "white",
                                    borderRadius: "4px",
                                }}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />

                            {(filteredQuestions && filteredQuestions.length > 0 ? filteredQuestions : questionList) && (
                                <List>
                                    {(filteredQuestions && filteredQuestions.length > 0 ? filteredQuestions : questionList).map((question) => (
                                        <ListItemButton
                                            sx={{ textAlign: "start", fontFamily: "Verdana" }}
                                            key={question.id}
                                            selected={selectedItem === question}
                                            onClick={() => {
                                                handleClick(question);
                                                //setSelectedItem(question.id);
                                            }}
                                        >
                                            {question.question}
                                        </ListItemButton>
                                    ))}
                                </List>
                            )}

                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleAdd()}
                                sx={{ mb: 3, backgroundColor: "#4CAF50" }}
                            >
                                Lägg till <AddIcon sx={{ ml: 0.5 }} fontSize="small" />
                            </Button>

                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Paper
                            sx={{
                                overflow: "auto",
                                width: 1,
                                height: 590,
                                backgroundColor: "white",
                                textAlign: "Center",
                                borderColor: "#4CAF50",
                                borderStyle: "solid",
                                borderRadius: "10px",
                                padding: 2,
                                marginLeft: -2,
                            }}
                        >
                            <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                    py: 2,
                                    fontFamily: ["Arial"],
                                    letterSpacing: 2,
                                    m: 1,
                                }}
                            >
                                Redigera Fråga
                            </Typography>
                            <TextField
                                sx={{
                                    width: "100%",
                                    pb: 1,
                                }}
                                fullWidth
                                placeholder="Fråga"
                                inputprops={{ sx: { height: 55 } }}
                                value={questionText}
                                onChange={(e) => setQuestionText(e.target.value)}
                            ></TextField>
                            <FormControl sx={{ width: "100%" }} inputprops={{ sx: { height: 55 } }} color="primary">
                                <InputLabel id="select-species-label">Välj art</InputLabel>
                                <Select
                                    labelId="select-species-label"
                                    label="Välj art för att markera"
                                    style={{ width: "100%" }}
                                    value={selectedSpecies || ""}
                                    onChange={(event) => handleCheck(event.target.value)}
                                >
                                    {species &&
                                        species.map((species) => (
                                            <MenuItem key={species.id} value={species.id}>
                                                {species.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>

                            <Typography
                                fontSize="25px"
                                align="center"
                                gutterBottom
                                sx={{
                                    fontFamily: ["Arial"],
                                    letterSpacing: 2,
                                    mt: 2,
                                }}
                            >
                                Fyll i svarsalternativ
                            </Typography>
                            <Box
                                sx={{
                                    backgroundColor: "#fff",
                                    py: 2,
                                    borderRadius: "20px",
                                    borderColor: "white",
                                    borderStyle: "solid",
                                }}
                            >
                                <Box>
                                    <div>
                                        {answer1 && answer1.startsWith("data:image") ? (
                                            <div>
                                                <img alt="not found" width={"100%"} height={100} src={answer1} />
                                                <button onClick={() => handleRemoveImage("answer 1")}>
                                                    Radera bild
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <TextField
                                                    sx={{
                                                        width: "100%",
                                                        backgroundColor: "#90ee90",
                                                        mb: 1,
                                                    }}
                                                    placeholder="1"
                                                    value={answer1}
                                                    onChange={(e) => setAnswer1(e.target.value)}
                                                />
                                                <label htmlFor="answer1-file">
                                                    <input
                                                        type="file"
                                                        name="imageUpload"
                                                        accept="image/*"
                                                        style={{ display: "none" }}
                                                        id="answer1-file"
                                                        onChange={handleFileChange1}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        component="span"
                                                        sx={{ ml: 0.5, backgroundColor: "#4CAF50" }}
                                                    >
                                                        <CameraAltIcon fontSize="small" />
                                                    </Button>
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </Box>
                                <Box>
                                    <div>
                                        {answer2 && answer2.startsWith("data:image") ? (
                                            <div>
                                                <img alt="not found" width={100} height={100} src={answer2} />
                                                <button onClick={() => handleRemoveImage("answer 2")}>
                                                    Radera bild
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <TextField
                                                    sx={{
                                                        width: "100%",
                                                        backgroundColor: "#fb32378c",
                                                        mb: 1,
                                                    }}
                                                    placeholder="X"
                                                    value={answer2}
                                                    onChange={(e) => setAnswer2(e.target.value)}
                                                />
                                                <label htmlFor="answer2-file">
                                                    <input
                                                        type="file"
                                                        name="imageUpload"
                                                        accept="image/*"
                                                        style={{ display: "none" }}
                                                        id="answer2-file"
                                                        onChange={handleFileChange2}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        component="span"
                                                        sx={{ ml: 0.5, backgroundColor: "#4CAF50" }}
                                                    >
                                                        <CameraAltIcon fontSize="small" />
                                                    </Button>
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </Box>
                                <Box>
                                    <div>
                                        {answer3 && answer3.startsWith("data:image") ? (
                                            <div>
                                                <img alt="not found" width={100} height={100} src={answer3} />
                                                <button onClick={() => handleRemoveImage("answer 3")}>
                                                    Radera bild
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <TextField
                                                    sx={{
                                                        width: "100%",
                                                        backgroundColor: "#fb32378c",
                                                        mb: 1,
                                                    }}
                                                    placeholder="2"
                                                    value={answer3}
                                                    onChange={(e) => setAnswer3(e.target.value)}
                                                />
                                                <label htmlFor="answer3-file">
                                                    <input
                                                        type="file"
                                                        name="imageUpload"
                                                        accept="image/*"
                                                        style={{ display: "none" }}
                                                        id="answer3-file"
                                                        onChange={handleFileChange3}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        component="span"
                                                        sx={{ ml: 0.5, backgroundColor: "#4CAF50" }}
                                                    >
                                                        <CameraAltIcon fontSize="small" />
                                                    </Button>
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </Box>
                            </Box>
                            <div>
                                {imageFileEncodedQ && (
                                    <div>
                                        <img alt="not found" width={"100%px"} height={"100%"} src={imageFileEncodedQ} />
                                        <br />
                                        <Button
                                            variant="outlined"
                                            size="sm"
                                            onClick={() => {
                                                setImageFileEncodedQ(null);
                                                handleFileChangeQ(null);
                                            }}
                                        >
                                            Ta bort
                                        </Button>
                                    </div>
                                )}
                                {audioFile && (
                                    <div>
                                        <audio
                                            alt="not fount"
                                            width={"100%"}
                                            height={"100%"}
                                            src={URL.createObjectURL(audioFile)}
                                        />
                                        <br />
                                        <button variant="outlined" size="sm" onClick={() => setAudioFile(null)}>
                                            Ta bort
                                        </button>
                                    </div>
                                )}
                                <br />
                                <br />
                                <Stack
                                    sx={{ width: 1, pb: 2 }}
                                    direction="row"
                                    spacing={1}
                                    justifyContent="space-around"
                                >
                                    <label htmlFor="image-file">
                                        <input
                                            type="file"
                                            name="imageUpload"
                                            accept="image/*"
                                            style={{ display: "none" }}
                                            id="image-file"
                                            onChange={handleFileChangeQ}
                                        />
                                        <Button
                                            variant="contained"
                                            component="span"
                                            sx={{ width: "100%" , backgroundColor: "#4CAF50" }}
                                        >
                                            Ladda upp bild <CameraAltIcon fontSize="small" />
                                        </Button>
                                    </label>

                                    <AudioUpload />
                                    <Button color="error"
                                            variant="contained"
                                            onClick={handleDeleteQuestion}
                                            sx={{ ml: 0.5, width: "25%", backgroundColor: "red" }}
                                    >
                                        Radera
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSave}
                                        sx={{ ml: 0.5, width: "25%", backgroundColor: "#4CAF50" }}
                                    >
                                        Spara ändring
                                    </Button>
                                </Stack>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}
