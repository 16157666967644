import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AudioPlayer from "../hooks/AudioPlayer";

/**
 * React component used to display data for a single species
 * in the <SpeciesInfo> component.
 * @param name
 * @param description
 * @param imgPath
 * @returns {JSX.Element}
 * @constructor
 */
const SpeciesInfoDetails = ({ name, description, imgPath, sound }) => {
    return (
        <Accordion sx={{ p: 1 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>{name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography gutterBottom>{description}</Typography>
                <Box
                    component="img"
                    sx={{
                        height: 1,
                        width: 1,
                        borderRadius: 1,
                    }}
                    src={imgPath}
                    alt="Ingen bild tillgänglig"
                />
                {sound && (
                    <AudioPlayer url={sound} />
                    )}
            </AccordionDetails>
        </Accordion>
    );
};

export default SpeciesInfoDetails;
