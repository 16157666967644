import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    OutlinedInput,
    Select,
    Button,
    TextField,
    MenuItem,
    ListItemText
} from "@mui/material";
import { Link } from "react-router-dom";
import useGET from "../http/useGET";
import { AppBar } from "../appbar";

/**
 * React component allows user to mark the different species that they have found in the area.
 * @returns {JSX.Element}
 */
export default function MarkingPage() {
    const { data: areas } = useGET(`${process.env.REACT_APP_API_URL}/areas`);
    const [areaSpecies, setAreaSpecies] = useState([]);
    const [areaName, setArea] = useState([]);
    const [selectedSpecies, setSelectedSpecies] = useState({});
    const [totalSelectedCheckboxes, setTotalSelectedCheckboxes] = useState(0);

    const fetchSpeciesFromArea = async (area) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/species/${area}`);
            if (!response.ok) throw new Error("Failed to fetch species");
            const updatedSpecies = await response.json();
            setAreaSpecies(updatedSpecies);
        } catch (error) {
            console.error("Request failed:", error);
            alert("Ett fel uppstod när arter skulle hämtas.");
        }
    };

    const handleSelectedArea = (event) => {
        const { value } = event.target;
        setArea(value);
        fetchSpeciesFromArea(value);
    };

    const handleCheck = (id, checked) => {
        setSelectedSpecies((prevState) => ({
            ...prevState,
            [`${id}`]: checked,
        }));
        const totalChecked = Object.values({ ...selectedSpecies, [`${id}`]: checked }).filter(Boolean).length;
        setTotalSelectedCheckboxes(totalChecked);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <AppBar />

            <Box sx={{ flexGrow: 1, padding: 3 }}>
                <Grid container spacing={4} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                    {/* Vänsterkolumn */}
                    <Grid
                        item
                        xs={12}
                        md={3}
                        sx={{
                            display: 'flex',
                            justifyContent: { xs: 'center', md: 'flex-start' },
                        }}
                    >
                        <Box sx={{
                            width: { xs: '100%', sm: '100%', md: '300px' },
                            padding: 2,
                            boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                            borderRadius: "8px",
                            backgroundColor: "#f8f8f8",
                            height: '350px',
                        }}>
                            <Typography
                                variant="h5"
                                sx={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold", color: "#698681" }}
                            >
                                Markera arter
                            </Typography>

                            <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                                <InputLabel>Välj område</InputLabel>
                                <Select
                                    value={areaName}
                                    onChange={handleSelectedArea}
                                    input={<OutlinedInput label="Välj område" />}
                                >
                                    {areas && areas.map((area) => (
                                        <MenuItem key={area.name} value={area.name}>
                                            <ListItemText primary={area.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                id="search-species"
                                label="Sök art"
                                type="search"
                                variant="outlined"
                                fullWidth
                                sx={{ marginBottom: "20px" }}
                            />

                            <Typography
                                variant="h6"
                                sx={{ textAlign: "center", marginBottom: "20px", color: "#698681" }}
                            >
                                {totalSelectedCheckboxes}/40 arter valda
                            </Typography>

                            <Link
                                to={{ pathname: "/Quiz", state: { speciesOut: selectedSpecies, areaName: areaName } }}
                                style={{ textDecoration: "none" }}
                            >
                                <Button
                                    variant="contained"
                                    color="success"
                                    fullWidth
                                    disabled={totalSelectedCheckboxes < 40}
                                >
                                    Gå vidare
                                </Button>
                            </Link>
                        </Box>
                    </Grid>

                    {/* Högerkolumn */}
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={2}>
                            {areaSpecies && areaSpecies.map((species) => (
                                <Grid item xs={12} sm={6} md={4} key={species.id}>
                                    <Card sx={{ display: "flex", alignItems: "center", padding: "10px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!!selectedSpecies[species.id]}
                                                    onChange={(event) => handleCheck(species.id, event.target.checked)}
                                                />
                                            }
                                            label={species.name}
                                        />
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
