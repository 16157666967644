import React from "react";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js"; // Importera CryptoJS
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import Footer from "../main/Footer";
import NaturmarketImage from "../img/Naturmarket.png";
import defaultTheme from "../theme";

const secretKey = "din-hemliga-nyckel"; // Samma nyckel som i App.js och SignIn.js

// Funktion för att dekryptera cookies
const decryptCookie = (encryptedValue) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
        return bytes.toString(CryptoJS.enc.Utf8) || null;
    } catch (error) {
        console.error("Error decrypting cookie:", error);
        return null;
    }
};

export default function AdminHome() {
    const [role, setRole] = useState(null);

    useEffect(() => {
        const encryptedAccess = Cookies.get("access");
        console.log("Encrypted access cookie:", encryptedAccess); // Debug
        if (encryptedAccess) {
            const role = decryptCookie(encryptedAccess);
            console.log("Decrypted role:", role); // Debug
            setRole(role);
        }
    }, []);

    return role === "ADMIN" ? (
        <ThemeProvider theme={defaultTheme}>
            <AppBar position="relative" style={{ backgroundColor: "#8EA534", padding: "10px" }}>
                <Toolbar>
                    <div style={{ display: "flex", alignItems: "center", padding: "1rem" }}>
                        <img
                            src={NaturmarketImage}
                            alt="Naturmärket"
                            style={{ width: "100px", height: "100px", marginRight: "1rem", borderRadius: "50%" }}
                        />
                        <Typography variant="h3" style={{ color: "white", fontSize: "1.5rem" }}>
                            Naturborgarmärket
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Link
                to="/home"
                style={{ textDecoration: "none", position: "absolute", top: 150, left: 0, margin: "16px" }}
            >
                <Button variant="contained" size="large" color="success">
                    Tillbaka
                </Button>
            </Link>
            <CssBaseline />
            <main>
                <Box bgcolor="#f8f8f8" sx={{ pt: 5, pb: 5 }}>
                    <Container maxWidth="sm">
                        <Typography
                            component="h1"
                            variant="h4"
                            align="center"
                            gutterBottom

                            sx={{ fontFamily: ["roboto"], letterSpacing: 2, m: 1, pt: 4, color: "primary" }}
                        >
                            Välkommen Admin!
                        </Typography>
                    </Container>
                </Box>
                <Box bgcolor="#f8f8f8">
                    <Container sx={{ pt: 2, pb: 8 }} maxWidth="md">
                        <Grid container spacing={2} paddingBottom="15px">
                            <Grid item xs={12}>
                                <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Redigera artlista
                                        </Typography>
                                        <Typography>
                                            Här kan du lägga till nya arter i en existerande lista eller skapa en ny lista
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Link to="/AdminSpeciesList" style={{ textDecoration: "none" }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    bgcolor: "#1976d3",
                                                    color: "white",
                                                    "&:hover": {
                                                        bgcolor: "#135aa1",
                                                    },
                                                }}
                                            >
                                                Öppna
                                            </Button>
                                        </Link>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} paddingBottom="15px">
                            <Grid item xs={12}>
                                <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Redigera quizfrågor
                                        </Typography>
                                        <Typography>
                                            Här kan du redigera och lägga till nya quizfrågor.
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Link to="/AdminEditQuiz" style={{ textDecoration: "none" }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    bgcolor: "#1976d3",
                                                    color: "white",
                                                    "&:hover": {
                                                        bgcolor: "#135aa1",
                                                    },
                                                }}
                                            >
                                                Öppna
                                            </Button>
                                        </Link>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} paddingBottom="15px">
                            <Grid item xs={12}>
                                <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            Redigera naturområde
                                        </Typography>
                                        <Typography>
                                            Här kan du redigera och lägga till ett nytt naturområde.
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Link to="/AdminNatureMap" style={{ textDecoration: "none" }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    bgcolor: "#1976d3",
                                                    color: "white",
                                                    "&:hover": {
                                                        bgcolor: "#135aa1",
                                                    },
                                                }}
                                            >
                                                Öppna
                                            </Button>
                                        </Link>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </main>
            <Footer></Footer>
        </ThemeProvider>
    ) : (
        <div>
            <Typography variant="h5" style={{ color: "red", textAlign: "center", marginTop: "20px" }}>
                Du har inte behörighet att visa denna sida.
            </Typography>
        </div>
    );
}
