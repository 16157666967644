import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useGET from "../http/useGET";
import NaturmarketImage from "../img/Naturmarket.png";
import { useLocation } from "react-router-dom";
import {getToken} from "../http/tokenGET";
import TextField from "@mui/material/TextField";

export default function AdminEditMap() {
    const { data: species, isPending } = useGET(`${process.env.REACT_APP_API_URL}/species`);
    const [selectedSpecies, setSelectedSpecies] = useState([]);
    const [selectedAreaSpecies, setSelectedAreaSpecies] = useState([]);
    const [areaName, setAreaName] = useState("");
    const [areaId, setAreaId] = useState(null);
    const [areaSpecies, setAreaSpecies] = useState([]);
    const [speciesSearch, setSpeciesSearch] = useState(""); // State for species search in left list
    const [areaSpeciesSearch, setAreaSpeciesSearch] = useState(""); // State for species search in right list
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const queryAreaName = queryParams.get("area");
        const queryAreaId = queryParams.get("areaId");

        if (queryAreaName) {
            setAreaName(queryAreaName);
        }
        if (queryAreaId) {
            setAreaId(queryAreaId);
        }
    }, [location.search]);

    useEffect(() => {
        console.log("areaId:", areaId);
        if (areaId) {
            fetch(`${process.env.REACT_APP_API_URL}/admin/area/${areaId}/species`)
                .then((response) => response.json())
                .then((data) => {
                    setAreaSpecies(data.species);
                })
                .catch((error) => console.error("Error fetching species:", error));
        }
    }, [areaId]);

    const handleSelectSpecies = (speciesItem) => {
        setSelectedAreaSpecies([]); // Clear selection from the other list
        setSelectedSpecies((prev) =>
            prev.includes(speciesItem)
                ? prev.filter((item) => item !== speciesItem) // Deselect if already selected
                : [...prev, speciesItem] // Select if not selected
        );
    };

    const handleSelectAreaSpecies = (speciesItem) => {
        setSelectedSpecies([]); // Clear selection from the other list
        setSelectedAreaSpecies((prev) =>
            prev.includes(speciesItem)
                ? prev.filter((item) => item !== speciesItem) // Deselect if already selected
                : [...prev, speciesItem] // Select if not selected
        );
    };

    const handleAddSpeciesToZone = async () => {
        if (!selectedSpecies.length) {
            console.warn("No species selected.");
            return; // Exit if no species are selected
        }
        const speciesIds = selectedSpecies.map(species => species.id);

        try {
            const token = await getToken(); // Ensure you are getting the token correctly
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/area/${areaId}/species`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-TOKEN": token,
                },
                body: JSON.stringify({
                    species_ids: speciesIds
                }),
            });

            // Check if the response is successful
            if (!response.ok) {
                await response.text(); // Read the response body as text for errors
                throw new Error("Failed to add species. Server responded with an error.");
            }

            // Check if the response is JSON
            const contentType = response.headers.get("Content-Type");
            if (contentType && contentType.includes("application/json")) {
                const data = await response.json(); // Parse JSON if it's valid
                console.log("Species added successfully:", data);

                // Update area species list
                setAreaSpecies((prev) => [...prev, ...selectedSpecies]);
                setSelectedSpecies([]); // Clear selection
            }
        } catch (error) {
            console.error("Error adding species:", error);
        }
    };

    const handleRemoveAllSpeciesFromZone = async () => {
        try {
            const token = await getToken();

            // Prepare an array of species IDs to remove
            const speciesIds = selectedAreaSpecies.map(species => species.id);

            if (speciesIds.length === 0) {
                console.log("No species selected for removal.");
                return;
            }


            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/area/${areaId}/species`, {
                method: 'DELETE', // DELETE request for removing species
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-CSRF-TOKEN': token,
                },
                body: JSON.stringify({ species_ids: speciesIds })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Failed to remove species");
            }
            setAreaSpecies((prev) =>
                prev.filter(species => !speciesIds.includes(species.id))
            );


            setSelectedAreaSpecies([]);
            setSelectedSpecies([]);

            console.log("Species removed successfully");
        } catch (error) {
            console.error("Error removing species:", error);
        }
    };




    const theme = createTheme({
        typography: {
            fontFamily: ["roboto", "sans-serif"].join(","),
        },
        palette: {
            primary: { main: "#698681" },
            secondary: { main: "#d9e0df" },
        },
    });

    const filteredSpecies = species?.filter((x) => x.name.toLowerCase().includes(speciesSearch.toLowerCase()));
    const filteredAreaSpecies = areaSpecies?.filter((x) => x.name.toLowerCase().includes(areaSpeciesSearch.toLowerCase()));


    return (
        <ThemeProvider theme={theme}>
        <AppBar position="relative" style={{ backgroundColor: "#8EA534", padding: "10px" }}>
            <Toolbar>
                <div style={{ display: "flex", alignItems: "center", padding: "1rem" }}>
                    <img
                        src={NaturmarketImage}
                        alt="Naturmärket"
                        style={{ width: "100px", height: "100px", marginRight: "1rem", borderRadius: "50%" }}
                    />
                    <Typography variant="h3" style={{ color: "white", fontSize: "1.5rem"}}>
                        Naturborgarmärket
                    </Typography>
                </div>
            </Toolbar>
        </AppBar>
        <Link
            to="/AdminNatureMap"
            style={{ textDecoration: "none", position: "absolute", top: 150, left: 0, margin: "16px" }}
        >
            <Button variant="contained" size="large" color="success">
                Tillbaka
            </Button>
        </Link>
        <Box bgcolor="secondary.main" sx={{ pt: 6 }}>
            <Container maxWidth="xl">
                <Typography
                    component="h1"
                    variant="h4"
                    align="center"
                    gutterBottom
                    sx={{
                        fontFamily: [theme],
                        letterSpacing: 2,
                        m: 1,
                        pt: 4,
                        color: "primary",
                    }}
                >
                    Redigera Artlista
                </Typography>
            </Container>
            </Box>
            <Container maxWidth="lg" sx={{ pb: 10 }}>
                <Grid container spacing={2} alignItems="stretch">
                    {/* Species List on the Left */}
                    <Grid item xs={12} sm={12} md={5}>
                        <Paper
                            sx={{
                                height: 650,
                                overflowY: "auto",
                                width: 1,
                                backgroundColor: "white",
                                textAlign: "center",
                                borderColor: "#719977",
                                borderStyle: "solid",
                                borderRadius: "5px",
                                padding: 2, // Increase padding for consistency
                            }}
                        >
                            <Typography variant="h5" sx={{ py: 2, fontFamily: "roboto", letterSpacing: 2 }}>
                                Artlista
                            </Typography>
                            <TextField
                                label="Sök arter"
                                variant="outlined"
                                fullWidth
                                value={speciesSearch}
                                onChange={(e) => setSpeciesSearch(e.target.value)}
                                sx={{ mb: 3 }} // Increased margin for better spacing
                            />
                            {!isPending && species && (
                                <Stack>
                                    {filteredSpecies.map((x) => (
                                        <Typography
                                            sx={{
                                                m: 2,
                                                py: 2,
                                                cursor: "pointer",
                                                border: selectedSpecies.includes(x)
                                                    ? "2px solid #8EA534"
                                                    : "1px solid #c7c7c7",
                                                borderRadius: "5px",
                                                backgroundColor: selectedSpecies.includes(x)
                                                    ? "#eaf5d3"
                                                    : "transparent",
                                            }}
                                            key={x.id}
                                            onClick={() => handleSelectSpecies(x)}
                                        >
                                            {x.name}
                                        </Typography>
                                    ))}
                                </Stack>
                            )}
                        </Paper>
                    </Grid>

                    {/* Buttons in the Center */}
                    <Grid item xs={12} sm={12} md={2}>
                        <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                            <button
                                style={{
                                    padding: "10px 20px",
                                    width: "150px",
                                    borderRadius: "5px",
                                    border: "none",
                                    backgroundColor: "#8EA534",
                                    color: "white",
                                    cursor: "pointer",
                                }}
                                onClick={handleAddSpeciesToZone}
                                disabled={!selectedSpecies.length}
                            >
                                Lägg till
                            </button>
                            {selectedAreaSpecies.length > 0 && (
                                <button
                                    style={{
                                        padding: "10px 20px",
                                        width: "150px",
                                        borderRadius: "5px",
                                        border: "none",
                                        backgroundColor: "#8B0000",
                                        color: "white",
                                        cursor: "pointer",
                                        marginTop: "20px",
                                    }}
                                    onClick={handleRemoveAllSpeciesFromZone}
                                >
                                    Ta bort
                                </button>
                            )}
                        </Stack>
                    </Grid>

                    {/* Right Square */}
                    <Grid item xs={12} sm={12} md={5}>
                        <Paper
                            sx={{
                                height: 650,
                                overflowY: "auto",
                                width: 1,
                                backgroundColor: "white",
                                textAlign: "center",
                                borderColor: "#719977",
                                borderStyle: "solid",
                                borderRadius: "5px",
                                padding: 2, // Increased padding for consistency
                            }}
                        >
                            <Typography variant="h5" sx={{ py: 2, fontFamily: "roboto", letterSpacing: 2 }}>
                                {areaName}
                            </Typography>
                            <TextField
                                label="Sök arter i zonen"
                                variant="outlined"
                                fullWidth
                                value={areaSpeciesSearch}
                                onChange={(e) => setAreaSpeciesSearch(e.target.value)}
                                sx={{ mb: 3 }} // Increased margin for better spacing
                            />
                            {Array.isArray(areaSpecies) && areaSpecies.length > 0 ? (
                                <Stack>
                                    {filteredAreaSpecies.map((speciesItem) => (
                                        <Typography
                                            sx={{
                                                m: 2,
                                                py: 2,
                                                cursor: "pointer",
                                                border: selectedAreaSpecies.includes(speciesItem)
                                                    ? "2px solid #8EA534"
                                                    : "1px solid #c7c7c7",
                                                borderRadius: "5px",
                                                backgroundColor: selectedAreaSpecies.includes(speciesItem)
                                                    ? "#eaf5d3"
                                                    : "transparent",
                                            }}
                                            key={speciesItem.id}
                                            onClick={() => handleSelectAreaSpecies(speciesItem)}
                                        >
                                            {speciesItem.name}
                                        </Typography>
                                    ))}
                                </Stack>
                            ) : (
                                <Typography>Inga arter funna i denna zon.</Typography>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}
