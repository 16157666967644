import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import SpeciesInfoDetails from "./SpeciesInfoDetails";
import useGET from "../http/useGET";
import Typography from "@mui/material/Typography";
import { AppBar } from "../appbar";

export default function SpeciesInfo() {
    const { data: species } = useGET(`${process.env.REACT_APP_API_URL}/species`);
    const { data: areas } = useGET(`${process.env.REACT_APP_API_URL}/areas`);
    const [sounds, setSounds] = useState([]);
    const [selectedArea, setSelectedArea] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [areaSpecies, setAreaSpecies] = useState([]);

    const handleFilterChange = (event) => {
        const { value } = event.target;
        setSelectedArea(value);
        fetchSpeciesFromArea(value);
    };

    const fetchSpeciesFromArea = async (area) => {
        if (!area) {
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/species/${area}`);
            const updatedSpecies = await response.json();
            setAreaSpecies(updatedSpecies);
        } catch (error) {
            console.error("Request failed:", error);
            alert("An error occurred while fetching species.");
        }
    };

    useEffect(() => {
        const fetchSounds = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/species/sounds`);
                const data = await response.json();
                setSounds(data); // Set sounds data
            } catch (error) {
                console.error("Error fetching sounds:", error);
            }
        };
        fetchSounds();
    }, []);

    const filteredSpecies = (selectedArea ? areaSpecies : species)?.filter(
        (s) =>
            (!searchTerm || s.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
                backgroundColor: "#fff",
            }}
        >
            {/* Header */}
            <AppBar />

            {/* Sök och filtrera */}
            <Box
                sx={{
                    padding: "30px",
                    borderRadius: "8px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#f8f8f8",
                    width: "100%",
                    maxWidth: "600px",
                    margin: "0 auto",
                    marginTop: "60px",
                }}
            >
                <Typography
                    variant="h4"
                    sx={{ textAlign: "center", marginBottom: "20px", fontWeight: "bold", color: "#698681" }}
                >
                    Arter i Naturområde
                </Typography>

                {/* Sökfält */}
                <TextField
                    id="search-field"
                    label="Sök art"
                    type="search"
                    variant="outlined"
                    fullWidth
                    sx={{
                        marginBottom: "25px",
                        backgroundColor: "white",
                        borderRadius: "4px",
                    }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />

                {/* Filtreringsfält */}
                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: "25px",
                        backgroundColor: "white",
                        borderRadius: "4px",
                    }}
                >
                    <InputLabel id="filter-label">Filtrera</InputLabel>
                    <Select
                        labelId="filter-label"
                        id="filter-field"
                        value={selectedArea}
                        onChange={handleFilterChange}
                        input={<OutlinedInput label="Filtrera" />}
                    >
                        {areas &&
                            areas.map((area) => (
                                <MenuItem key={area.name} value={area.name}>
                                    <Checkbox checked={selectedArea === area.name} />
                                    <ListItemText primary={area.name} />
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Box>

            {/* Lista över arter */}
            <Box sx={{ width: "100%", padding: "20px" }}>
                {filteredSpecies &&
                    filteredSpecies.map((specie) => {
                        const soundPath = sounds.find((s) => s.specie_id === specie.id)?.file_path;

                        return (
                            <SpeciesInfoDetails
                                key={specie.name}
                                name={specie.name}
                                description={specie.description}
                                imgPath={`${process.env.REACT_APP_API_URL}/img/${specie.data_path}`}
                                sound={soundPath ? `${process.env.REACT_APP_API_URL}/sounds/${soundPath}` : null}
                            />
                        );
                    })}
            </Box>
        </Box>
    );
}
