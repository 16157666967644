import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { Link, useParams } from "react-router-dom";
import useGET from "../http/useGET";

/**
 * React component that acts as a container for the
 * map component that is used to display marked nature areas.
 * Fetches the data that is needed on initial render and then
 * uses it to create a custom <MapComponent>
 * @returns {JSX.Element}
 * @constructor
 */

export default function NatureMap() {
    const { centerName: areaName } = useParams(); // selected area name
    const { data: areapins, isPending, error, response } = useGET(`${process.env.REACT_APP_API_URL}areas/${areaName}/pins`);

    console.log(areapins); // Just to avoid unused-vars warning
    console.log(isPending); // Just to avoid unused-vars warning
    console.log(error); // Just to avoid unused-vars warning
    console.log(response); // Just to avoid unused-vars warning

    return (
        <div>
            <CssBaseline />
            <React.Fragment>
                <Box
                    sx={{
                        bgcolor: "secondary.main",
                        pt: 5,
                        pb: 1,
                    }}
                >
                    <Container maxWidth="sm">
                        <Typography
                            component="h1"
                            variant="h4"
                            align="center"
                            gutterBottom
                            sx={{
                                fontFamily: ["Roboto", "arial"],
                                letterSpacing: 2,
                                m: 1,
                                color: "primary.main",
                            }}
                        >
                            {areaName}
                        </Typography>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                py: 3,
                                borderRadius: "20px",
                                borderColor: "primary.main",
                                borderStyle: "solid",
                            }}
                        >
                            <Box sx={{ m: 1 }}>
                                <Typography align="center">
                                    Nedan visas det naturområde som du befinner dig i och kommer att leta efter
                                    arter i. För att gå vidare till sidan där du markerar de arter som du har hittat,
                                    trycker du på "Gå till Quiz".
                                </Typography>
                            </Box>
                        </Box>
                    </Container>
                </Box>
                <Box bgcolor="secondary.main" sx={{ mb: 5 }}>
                    <Stack sx={{ pt: 3, width: 1, mb: 2 }} direction="row" spacing={2} justifyContent="center">
                        <Link to="/ChooseArea" style={{ textDecoration: "none" }}>
                            <Button variant="contained">Tillbaka</Button>
                        </Link>
                        {/* <Link to={`/MarkingPage/${areaName}`} style={{ textDecoration: "none" }}>
                            <Button variant="contained">Gå till Quiz</Button>
                        </Link> */}
                    </Stack>
                    <div>
                        {/* CUSTOM MAP COMPONENT */}
                        {/* {!isPending && areapins && (
                            <MapComponent areas={areapins} center={areapins[areaName].center} areaName={areaName} />
                        )} */}
                    </div>
                </Box>
            </React.Fragment>
        </div>
    );
}
