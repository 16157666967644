import CryptoJS from "crypto-js"; // Importera CryptoJS
import Cookies from "js-cookie";
import "./css/App.css";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./main/Home";
import SignIn from "./login/SignIn";
import SignUp from "./login/SignUp";
import SignOut from "./login/SignOut";
import UserProfile from "./login/UserProfile";
import Quiz from "./quiz/Quiz";
import AboutNB from "./main/AboutNB";
import MarkingPage from "./quiz/MarkingPage";
import ChooseArea from "./map/ChooseArea";
import NatureMap from "./map/NatureMap";
import AdminHome from "./admin/AdminHome";
import AdminSpeciesList from "./admin/AdminSpeciesList";
import ForgotPassword from "./login/ForgotPassword";
import ResetPassword from "./login/ResetPassword";
import AdminMap from "./admin/AdminMap";
import AccessDenied from "./error/AccessDenied";
import AdminNatureMap from "./admin/AdminNatureMap";
import AdminMapSpecies from "./admin/AdminMapSpecies";
import AdminEditQuiz from "./admin/AdminEditQuiz";
import MapTest from "./map/MapTest";
import WelcomePage from "./WelcomePage";
import SpeciesInfo from "./quiz/SpeciesInfo";
import AdminEditMap from "./admin/AdminEditMap";

const secretKey = "din-hemliga-nyckel"; // Samma nyckel som i SignIn.js

// Funktion för att dekryptera cookies
const decryptCookie = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
};

function App() {
    // Dekryptera "access"-cookien
    const encryptedAccess = Cookies.get("access");
    const accessRole = encryptedAccess ? decryptCookie(encryptedAccess) : null;

    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route exact path="/">
                        <WelcomePage />
                    </Route>

                    <Route exact path="/home">
                        <Home />
                    </Route>

                    <Route exact path="/SignIn">
                        <SignIn />
                    </Route>

                    <Route exact path="/SignUp">
                        <SignUp />
                    </Route>

                    <Route exact path="/SignOut">
                        <SignOut />
                    </Route>

                    <Route exact path="/ResetPassword">
                        <ResetPassword />
                    </Route>

                    <Route exact path="/ForgotPassword">
                        <ForgotPassword />
                    </Route>

                    <Route exact path="/UserProfile">
                        {accessRole === "USER" || accessRole === "ADMIN" ? (
                            <UserProfile />
                        ) : (
                            <AccessDenied />
                        )}
                    </Route>

                    <Route exact path="/MarkingPage">
                        <MarkingPage />
                    </Route>

                    <Route exact path="/ChooseArea">
                        <ChooseArea />
                    </Route>

                    <Route exact path="/NatureMap">
                        <NatureMap />
                    </Route>

                    <Route exact path="/AdminHome">
                        {accessRole === "ADMIN" ? (
                            <AdminHome />
                        ) : (
                            <AccessDenied info={"No admin access"} />
                        )}
                    </Route>

                    <Route exact path="/adminmap">
                        {accessRole === "ADMIN" ? <AdminMap /> : <AccessDenied />}
                    </Route>

                    <Route exact path="/adminspecieslist">
                        {accessRole === "ADMIN" ? <AdminSpeciesList /> : <AccessDenied />}
                    </Route>

                    <Route exact path="/admineditquiz">
                        {accessRole === "ADMIN" ? <AdminEditQuiz /> : <AccessDenied />}
                    </Route>

                    <Route exact path="/Quiz">
                        {
                            <Quiz />
                            // Cookies.get('access') === "USER" || Cookies.get('access') === "ADMIN" ? <Quiz /> : <AccessDenied />
                        }
                    </Route>

                    <Route exact path="/AboutNB">
                        <AboutNB />
                    </Route>

                    <Route exact path="/AdminNatureMap">
                        <AdminNatureMap />
                    </Route>

                    <Route exact path="/AdminMapSpecies">
                        <AdminMapSpecies />
                    </Route>

                    <Route exact path="/admineditmap">
                        {accessRole === "ADMIN" ? <AdminEditMap /> : <AccessDenied />}
                    </Route>

                    <Route exact path="/MapTest">
                        <MapTest />
                    </Route>
                    <Route exact path="/species-info">
                        <SpeciesInfo />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default App;
