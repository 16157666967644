import { useState } from "react";
import { getToken } from "../http/tokenGET";

export const ImageHandler = () => {
    const [imageFileEncoded, setImageFileEncoded] = useState("");

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append("image", file);

        try {
            const token = await getToken();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/species/uploadImage`, {
                method: "POST",
                credentials: 'include',
                headers: {
                    'X-CSRF-TOKEN': token
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`Image upload failed: ${response.statusText}`);
            }

            const data = await response.json();
            return data.fileName;
        } catch (error) {
            console.error("Error uploading image:", error);
            return null;
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (file) {
            const uploadedFileName = await uploadImage(file);
            if (uploadedFileName) {
                setImageFileEncoded(uploadedFileName);
            } else {
                console.error("Failed to upload image");
            }
        }
    };

    return {
        imageFileEncoded,
        setImageFileEncoded,
        handleFileChange,
    };
};
