import React from "react";
import Typography from "@mui/material/Typography";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Container, Box, Avatar, Grid, TextField, Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

/**
 * React component used as a placeholder for account management
 * for a USER. This version currently has little/no working functionality
 * implemented and is more a placeholder that needs to be adjusted and tested.
 * @returns {JSX.Element}
 * @constructor
 */
export default function UserProfile() {
    return (
        <Container component="main" maxWidth="xs" sx={{ bgcolor: "background.paper", p: 3 }}>
            <Box
                sx={{
                    marginTop: 13,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    boxShadow: 3,
                    p: 4,
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                    <PersonOutlineOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" sx={{ color: "text.primary", mb: 2 }}>
                    Redigera profil
                </Typography>
                <Box component="form" noValidate sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                fullWidth
                                id="firstName"
                                label="Redigera namn"
                                autoFocus
                                sx={{
                                    borderRadius: 1,
                                    backgroundColor: "white",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="password"
                                label="Redigera lösenord"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                sx={{
                                    borderRadius: 1,
                                    backgroundColor: "white",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                startIcon={<DeleteIcon />}
                                fullWidth
                                sx={{ backgroundColor: "error.main", color: "white" }}
                            >
                                Ta bort konto
                            </Button>
                        </Grid>
                    </Grid>
                    <Stack sx={{ pt: 3 }} direction="row" spacing={2} justifyContent="space-between">
                        <Link to="/" style={{ textDecoration: "none" }}>
                            <Button
                                color="primary"
                                sx={{ backgroundColor: "primary.main", color: "white" }}
                                variant="contained"
                                fullWidth
                            >
                                Avbryt
                            </Button>
                        </Link>
                        <Button
                            color="primary"
                            sx={{ backgroundColor: "primary.main", color: "white" }}
                            variant="contained"
                            fullWidth
                        >
                            Spara ändringar
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Container>
    );
}
