import React from "react";
import { AppBar as MuiAppBar, Toolbar, Typography, Button } from "@mui/material";
import NaturmarketImage from "./img/Naturmarket.png";
import { Link } from "react-router-dom";

/* Header för de andra sidorna */
export const AppBar = ({ title }) => {
    return (
        <MuiAppBar position="relative" sx={{ backgroundColor: "#8EA434", padding: { xs: "5px", md: "10px" } }}>
            <Toolbar
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                }}
            >
                <Link to="/home" style={{ textDecoration: "none" }}>
                    <Button
                        variant="contained"
                        color="success"
                        size="small"
                        sx={{
                            fontSize: { xs: "0.8rem", md: "1rem" },
                        }}
                    >
                        Tillbaka
                    </Button>
                </Link>

                <Typography
                    variant="h2"
                    sx={{
                        display: { xs: "none", md: "block" },
                        fontSize: "30px",
                        fontWeight: 570,
                        letterSpacing: "0.2rem",
                        textAlign: "center",
                        flex: 1,
                    }}
                >
                    {title || "NATURBORGARMÄRKET"}
                </Typography>
                <img
                    src={NaturmarketImage}
                    alt="Naturmärket"
                    style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                    }}
                />
            </Toolbar>
        </MuiAppBar>
    );
};
