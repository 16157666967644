export const getToken = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
        credentials: 'include',
    });

    if (!response.ok) {
        console.error('Failed to fetch token');  // Lägg till detta för att se felmeddelanden
        throw new Error('Failed to fetch token');
    }

    const data = await response.json();
    console.log('Fetched token:', data.token);  // Skriv ut token för att se om den hämtas rätt
    return data.token;
};