import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { List, ListItemButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import AdminMap from "./AdminMap";
import useGET from "../http/useGET";
import SingleFormModal from "../modal/SingleFormModal";
import ConfirmModal from "../modal/ConfirmModal";
import { usePolygon } from "../hooks/usePolygon";
import NaturmarketImage from "../img/Naturmarket.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { getToken } from "../http/tokenGET";
import defaultTheme from "../theme";

export default function AdminNatureMap() {
    const [areas, setAreas] = useState([]);
    const [selectedItem, setSelectedItem] = useState("");
    const [pinsUrl, setPinsUrl] = useState("");
    const [pins, setPins] = useState([]);
    const { updatePolygon, clearPolygon, updateCenter, shouldRender, render, polygon, center } = usePolygon();
    const { data: fetchedAreas, isPending } = useGET(`${process.env.REACT_APP_API_URL}/areas`);
    const areaNames = areas ? areas.map(area => area.name) : [];
    const { data: fetchedPins } = useGET(pinsUrl);
    const [hasCentered, setHasCentered] = useState(false);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [newAreaData, setNewAreaData] = useState({
        name: "",
        description: "",
        image_path: null,
    });

    useEffect(() => {
        if (fetchedAreas) {
            setSelectedItem(fetchedAreas[0].name);
            setAreas(fetchedAreas);
        }
    }, [fetchedAreas]);

    useEffect(() => {
        if (fetchedPins) {
            setPins(fetchedPins);
        }
    }, [fetchedPins]);

    useEffect(() => {
        if (selectedItem) {
            setPinsUrl(`${process.env.REACT_APP_API_URL}/areas/${selectedItem}/pins`);
            setPins([]);
        }
    }, [selectedItem]);

    const handleClick = (selected) => {
        clearPolygon();
        setSelectedItem(selected);
        setPins([]);
    };

    useEffect(() => {
        if (selectedItem && pins.length > 0 && !hasCentered) {
            const selectedArea = areas.find(area => area.name === selectedItem);

            if (selectedArea) {

                pins.sort((a, b) => a.pin_order - b.pin_order);
                updatePolygon(pins);


                const latitudes = pins.map(pin => pin.latitude);
                const longitudes = pins.map(pin => pin.longitude);

                const centerLatitude = latitudes.reduce((sum, lat) => sum + lat, 0) / latitudes.length;
                const centerLongitude = longitudes.reduce((sum, lng) => sum + lng, 0) / longitudes.length;


                updateCenter({ lat: centerLatitude, lng: centerLongitude });

                setHasCentered(true);
            }
        }
    }, [selectedItem, pins, areas, updatePolygon, updateCenter, hasCentered]);

    useEffect(() => {
        setHasCentered(false);
    }, [selectedItem]);

    const [isNameModalOpen, setNameModalOpen] = useState(false);

    const openNameModal = () => {
        setNameModalOpen(true);
    };

    const closeNameModal = () => {
        setNameModalOpen(false);
    };

    const openAddModal = () => {
        setAddModalOpen(true);
    };

    const closeAddModal = () => {
        setAddModalOpen(false);
        setNewAreaData({ name: "", description: "", image_path: null });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewAreaData({ ...newAreaData, image_path: file });
        }
    };

    const handleNameSubmit = async (data) => {
        closeNameModal();
        try {
            const token = await getToken();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/areas/${selectedItem}`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-CSRF-TOKEN': token
                },
                body: JSON.stringify({ newName: data.name })
            });

            if (response.status === 200) {
                setAreas(prevAreas => {
                    const updatedAreas = prevAreas.map(area => {
                        if (area.name === selectedItem) {
                            return { ...area, name: data.name };
                        }
                        return area;
                    });
                    return updatedAreas;
                });

                let ele = document.getElementById("area" + selectedItem);
                if (ele) {
                    ele.innerText = data.name;
                    ele.setAttribute("id", "area" + data.name);
                }

                setSelectedItem(data.name);
            } else {
                console.error(`handleNameSubmit: Response status did not return status code 200`);
            }
        } catch (error) {
            console.error("Error updating area name:", error);
        }
    };

    const handleAddArea = async () => {
        const formData = new FormData();
        formData.append("name", newAreaData.name);
        formData.append("description", newAreaData.description);
        formData.append("image_path", newAreaData.image_path);

        try {
            const token = await getToken();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/areas/new`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "X-CSRF-TOKEN": token,
                },
                body: formData,
            });
    
            if (!response.ok) {
                alert("Error adding the area!");
            } else {
                alert("New area added successfully!");
                closeAddModal();

                const addedAreaResponse = await response.json(); 
                const addedArea = addedAreaResponse.area;
                setAreas((prevAreas) => [...prevAreas, addedArea]);
            }
        } catch (error) {
            console.error("Error adding new area:", error);
        }
    };

    const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);

    const openRemoveModal = () => {
        if (selectedItem != null) {
            setRemoveModalOpen(true);
        }
    };

    const closeRemoveModal = () => {
        setRemoveModalOpen(false);
    };

    const handleRemoveSubmit = async () => {
        closeRemoveModal();
        try {
            const token = await getToken();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/areas/${selectedItem}`, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    'X-CSRF-TOKEN': token
                }
            });

            if (response.status === 200) {
                setAreas(prevAreas => {
                    const updatedAreas = prevAreas.filter(area => area.name !== selectedItem);
                    return updatedAreas;
                });
            } else {
                alert("Failed to delete area. Please try again.");
                console.error("Error:", response.statusText);
            }

            const remainingAreas = areas.filter(area => area.name !== selectedItem);
            if (remainingAreas.length > 0) {
                setSelectedItem(remainingAreas[0].name);
            } else {
                setSelectedItem("");
            }
        } catch(error) {
            alert("Something went wront. Please try again.");
            console.error(`Failed to delete area`);
        }
    };
  
    return (
        <ThemeProvider theme={defaultTheme}>
            <AppBar position="relative" style={{ backgroundColor: "#8EA534", padding: "10px" }}>
                <Toolbar>
                    <div style={{ display: "flex", alignItems: "center", padding: "1rem" }}>
                        <img
                            src={NaturmarketImage}
                            alt="Naturmärket"
                            style={{ width: "100px", height: "100px", marginRight: "1rem", borderRadius: "50%" }}
                        />
                        <Typography variant="h3" style={{ color: "white", fontSize: "1.5rem"}}>
                            Naturborgarmärket
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Link
                to="/adminHome"
                style={{ textDecoration: "none", position: "absolute", top: 150, left: 0, margin: "16px" }}
            >
                <Button variant="contained" size="large" color="success">
                    Tillbaka
                </Button>
            </Link>
            <Box bgcolor="#fff" sx={{ pt: 6 }}>
                <Container maxWidth="xl">
                    <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        gutterBottom
                        sx={{
                            fontFamily: defaultTheme.typography.fontFamily,
                            letterSpacing: 2,
                            m: 1,
                            pt: 4,
                            color: "primary",
                        }}
                    >
                        Redigera Naturområden
                    </Typography>
                </Container>
            </Box>
            <Container maxWidth="xl" sx={{ pt: 2, pb: 1, display: "flex", mb: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Paper
                            sx={{
                                overflow: "auto",
                                height: 550,
                                backgroundColor: "white",
                                textAlign: "center",
                                borderColor: "#719977",
                                borderStyle: "solid",
                                borderRadius: "5px",
                                padding: 2,
                            }}
                        >
                            <Typography
                                component="h1"
                                variant="h5"
                                sx={{
                                    fontFamily: defaultTheme.typography.fontFamily,
                                    letterSpacing: 2,
                                    m: 1,
                                    color: "primary",
                                }}
                            >
                                Naturområden
                            </Typography>
                            <Grid sx={{ pt: "17px", fontFamily: "Arial" }} item xs={12}>
                                {!isPending && areas && (
                                    <List id={"areaList"}>
                                        {areaNames
                                            .map((key) => (
                                                <ListItemButton
                                                    sx={{ textAlign: "center" }}
                                                    key={key}
                                                    selected={selectedItem === key}
                                                    onClick={() => handleClick(key)}
                                                    id={"area" + key}
                                                >
                                                    {key}
                                                </ListItemButton>

                                            ))}
                                    </List>
                                )}
                            </Grid>
                        </Paper>
                        <Button color="info" variant="contained" sx={{ mb: 2, mr: 2, mt: 2 }} onClick={openNameModal}>
                            Byt namn
                        </Button>
                        <Button color="error" variant="contained" sx={{ mb: 2, mr: 2, mt: 2 }} onClick={openRemoveModal}>
                            Ta bort
                        </Button>
                        <Button color="error" variant="contained" sx={{ mb: 2, mt: 2 }} onClick={() => {

                            if (selectedItem) {
                                const selectedArea = areas.find(area => area.name === selectedItem);
                                console.log(selectedArea);
                                if(selectedArea) {
                                    const areaId = selectedArea.id;
                                    console.log(areaId)
                                    const url = `/AdminEditMap?area=${encodeURIComponent(selectedItem)}&areaId=${encodeURIComponent(areaId)}`;
                                    console.log(url);
                                    window.location.href = url;
                                }

                               } else {
                                alert("Vänligen välj en zon innan du fortsätter.");
                            }
                        }}
                        >
                            Hantera zon
                        </Button>
                        <SingleFormModal
                            isOpen={isNameModalOpen}
                            onSubmit={handleNameSubmit}
                            onClose={closeNameModal}
                            text={selectedItem}
                        />
                        <ConfirmModal
                            isOpen={isRemoveModalOpen}
                            onSubmit={handleRemoveSubmit}
                            onClose={closeRemoveModal}
                            text={`Vill du ta bort ${selectedItem}?`}
                        ></ConfirmModal>
                        <Button color="primary" variant="contained" onClick={openAddModal}>
                            Lägg till naturområde
                        </Button>
                        <Dialog open={isAddModalOpen} onClose={closeAddModal}>
                            <DialogTitle>Lägg till naturområde</DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Titel"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={newAreaData.name}
                                    onChange={(e) => setNewAreaData({ ...newAreaData, name: e.target.value })}
                                />
                                <TextField
                                    margin="dense"
                                    label="Beskrivning"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    value={newAreaData.description}
                                    onChange={(e) => setNewAreaData({ ...newAreaData, description: e.target.value })}
                                />
                                <Input
                                    accept="image/"
                                    type="file"
                                    onChange={handleImageChange}
                                    inputProps={{ accept: "image/" }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeAddModal} color="primary">
                                    Avbryt
                                </Button>
                                <Button onClick={handleAddArea} color="primary">
                                    Spara
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                    {!isPending && (
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                            <Paper
                                sx={{
                                    overflow: "auto",
                                    height: 620,
                                    backgroundColor: "white",
                                    textAlign: "center",
                                    borderColor: "#719977",
                                    borderStyle: "solid",
                                    borderRadius: "5px",
                                    padding: 2,
                                }}
                            >
                                <AdminMap
                                    updatePolygon={updatePolygon}
                                    clearPolygon={clearPolygon}
                                    shouldRender={shouldRender}
                                    render={render}
                                    polygon={polygon}
                                    center={center}
                                    selectedArea={selectedItem}
                                ></AdminMap>
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </ThemeProvider>
    );
}