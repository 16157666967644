import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import Bird from "../img/bird.jpg";
import { getToken } from "../http/tokenGET";
import { useState } from "react";

/**
 * React component for user sign-up.
 * @returns {JSX.Element}
 * @constructor
 */
export default function SignUp() {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const user = {
            firstname: data.get("firstname"),
            lastname: data.get("lastname"),
            email: data.get("email"),
            password: data.get("password"),
        };

        try {
            const token = await getToken();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': token
                },
                credentials: 'include',
                body: JSON.stringify(user),
            });

            if (!response.ok) {
                const errorText = await response.text();
                setErrorMessage("Registreringen misslyckades: " + errorText);
                throw new Error(`Registration failed: ${errorText}`);
            }

            history.push("/signIn");
        } catch (error) {
            setErrorMessage("Ett fel inträffade vid registrering, vänligen försök igen.");
        }
    };

    return (
        <Grid container component="main" sx={{ height: "100vh", padding: 4 }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${Bird})`,
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "#8EA434" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{ color: "text.primary", mb: 2 }}>
                        Registrera Konto
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstname"
                            label="Förnamn"
                            name="firstname"
                            autoComplete="given-name"
                            autoFocus
                            sx={{ backgroundColor: "white" }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="lastname"
                            label="Efternamn"
                            type="text"
                            id="lastname"
                            autoComplete="family-name"
                            sx={{ backgroundColor: "white" }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-postadress"
                            name="email"
                            autoComplete="email"
                            sx={{ backgroundColor: "white" }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Lösenord (Minst 8 karaktärer)"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            sx={{ backgroundColor: "white" }}
                        />
                        {errorMessage && (
                            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                                {errorMessage}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                bgcolor: "#8EA434",
                                color: "white",
                                "&:hover": {
                                    bgcolor: "#6a7b2d",
                                },
                            }}
                        >
                            Registrera
                        </Button>
                        <Grid container>
                            <Link href="/signIn" variant="body2" sx={{ textDecoration: "none" }}>
                                {"Har du redan ett konto? Logga in"}
                            </Link>
                        </Grid>
                    </Box>
                </Box>
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        color: "text.primary",
                        padding: "2rem 0",
                        textAlign: "center",
                        width: "100%",
                        marginTop: "auto",
                    }}
                >
                    <Typography variant="body2">&copy; {new Date().getFullYear()} Naturborgarmärket</Typography>
                </Box>
            </Grid>
        </Grid>
    );
}
