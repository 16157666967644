import React, { useState, useEffect } from "react";
import { AppBar, Box, Container, Grid, Typography, Paper, Button, CssBaseline } from "@mui/material";
import "./css/App.css";
import { Link } from "react-router-dom";
import solochskog from "./img/solochskog.jpg";
import NaturmarketImage from "./img/Naturmarket.png";

// Header, overlay och och footer
const headerStyle = {
    backgroundImage: `url(${solochskog})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "white",
    position: "relative",
    height: "290px",
};

const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: -1,
};

function WelcomePage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

// Effekt som gör att utseendet i mobilläget ändras
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    // Gör så att bakgrunden ser bra ut i mobilläge
    const mobileBackgroundStyle = {
        backgroundImage: `url(${solochskog})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        width: "100%",
        zIndex: 1,
    };

    return (
        <div style={{display: "flex", flexDirection: "column", minHeight: "100vh"}}>
            <CssBaseline/>
            <div style={isMobile ? mobileBackgroundStyle : {}}>
                {isMobile && <Box sx={{ ...overlayStyle, zIndex: -1 }} />}
                {!isMobile && (
                    <AppBar position="static" style={headerStyle}>
                        <Box sx={{...overlayStyle, zIndex: 1}}/>
                        <Container>
                            <Grid container alignItems="center" spacing={4}>
                                <Grid item xs={12} sm={6} textAlign={{xs: 'center', sm: 'left'}}
                                      sx={{position: 'relative'}}>
                                    <div className="centered-text" style={{position: 'relative', zIndex: 1}}>
                                        <Typography variant="h2" className="straight-text" sx={{
                                            fontSize: {xs: '2rem', md: '54.9px'},
                                            fontWeight: 570,
                                            textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)',
                                            mt: {xs: 8, md: 16},
                                            mb: {xs: 3, md: 2},
                                            letterSpacing: '0.2rem'
                                        }}>
                                            NATURBORGARMÄRKET
                                        </Typography>
                                        <Typography variant="subtitle1"
                                                    className="straight-text"
                                                    sx={{
                                                        fontSize: {xs: '1rem', md: '28.1px'},
                                                        fontWeight: 400,
                                                        textShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)',
                                                        mb: 2,
                                                    }}>
                                            Det naturliga äventyret
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {/*No image*/}
                                </Grid>
                            </Grid>
                        </Container>
                    </AppBar>
                )}

                {/* Center Content */}
                <Container
                    sx={{
                        marginTop: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: {xs: "column", md: "row-reverse"},
                        py: 6,
                        px: 2,
                    }}>

                    <Paper
                        elevation={3}
                        sx={{
                            padding: 3,
                            display: {xs: "block", md: "none"},
                            backgroundColor: "white",
                            mb: 4,
                            textAlign: "center",
                        }}
                    >
                        <Box sx={{
                            display: "flex",
                            flexDirection: {xs: "column", md: "row-reverse"},
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: {xs: "center", md: "left"},
                        }}>

                            <img
                                src={NaturmarketImage}
                                alt="Naturborgarmarket"
                                style={{width: "19%", height: "auto", marginLeft: "auto", marginRight: "auto",}}
                            />

                            <Box sx={{flex: 1, mr: {md: 20}, mt: {xs: 2, md: -2}, mb: {xs: 2, md: 2}}}>
                            <Typography variant="h5" sx={{
                                    mb: 1,
                                    fontWeight: "bold",
                                }}>NATURBORGARMÄRKET</Typography>
                                <Typography variant="body1"
                                            sx={{mb: 2, lineHeight: 1.8, fontSize: {xs: "16px", md: "18px"}}}>
                                    Naturborgarmärket syftar till att utveckla allmänhetens kunskap om olika arter som
                                    finns
                                    i vår
                                    natur. Idén går ut på att skapa ett interaktivt lärande där du behöver befinna dig i
                                    naturen och
                                    aktivt leta efter och se olika arter, där kunskapen om dessa och allemansrätten
                                    sedan
                                    sätts på
                                    prov i ett kunskapstest. Om du klarar av kunskapstestet så belönas du med ett
                                    fysiskt
                                    naturborgarmärke som erhålls genom beställning efter avklarat test.
                                </Typography>
                                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: 2 }}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="success"
                                        sx={{
                                            textTransform: "none",
                                            padding: "10px 20px",
                                            fontSize: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            "&:hover": {
                                                backgroundColor: "#2E7D33",
                                            },
                                            mt: {xs: 2, md: 1},
                                            mx: {xs: "auto", md: 0},
                                        }}
                                        onClick={() => window.location.href = '/signup'}
                                    >
                                        Registrera konto
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        size="large"
                                        color="success"
                                        sx={{
                                            textTransform: "none",
                                            padding: "10px 20px",
                                            fontSize: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            "&:hover": {
                                                backgroundColor: "#e8f5e9",
                                            },
                                            mt: { xs: 2, md: 1 },
                                            mx: { xs: "auto", md: 0 },
                                        }}
                                        onClick={() => window.location.href = '/home'}
                                    >
                                        Fortsätt som gäst
                                    </Button>
                                    <Typography variant="body2" sx={{ mt: 1, textAlign: "center" }}>
                                        Har du redan ett konto?{" "}
                                        <Link to="/signin" style={{ color: "#2E7D33", textDecoration: "underline" }}>
                                            Logga in
                                        </Link>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                    <Box
                        sx={{
                            display: {xs: "none", md: "flex"}, // Dölj endast i mobil-läge
                            flexDirection: {md: "row-reverse"},
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "left",
                        }}
                    >
                        <img
                            src={NaturmarketImage}
                            alt="Naturborgarmarket"
                            style={{width: "19%", height: "auto", marginLeft: "auto", marginRight: "auto"}}
                        />
                        <Box sx={{flex: 1, mr: {md: 25}, mt: {xs: 4, md: 0}, mb: {xs: 3, md: 4}}}>
                            <Typography variant="h5" sx={{mb: 2, fontWeight: "bold"}}>
                                NATURBORGARMÄRKET
                            </Typography>
                            <Typography variant="body1"
                                        sx={{mb: 3, lineHeight: 1.8, fontSize: {xs: "16px", md: "18px"}}}>
                                Naturborgarmärket syftar till att utveckla allmänhetens kunskap om olika arter som finns
                                i vår
                                natur. Idén går ut på att skapa ett interaktivt lärande där du behöver befinna dig i
                                naturen och
                                aktivt leta efter och se olika arter, där kunskapen om dessa och allemansrätten sedan
                                sätts på
                                prov i ett kunskapstest. Om du klarar av kunskapstestet så belönas du med ett fysiskt
                                märke.
                            </Typography>
                            <div style={{textAlign: "center"}}>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    justifyContent: "flex-start",
                                    gap: 2,
                                    textAlign: "left",
                                    alignItems: "flex-start",
                                    mt: 2
                                }}>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="success"
                                        sx={{
                                            textTransform: "none",
                                            padding: "10px 20px",
                                            fontSize: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            "&:hover": {
                                                backgroundColor: "#2E7D33",
                                            },
                                            mt: {xs: 2, md: 1},
                                            mx: {xs: "auto", md: 0},
                                        }}
                                        onClick={() => window.location.href = '/signup'}
                                    >
                                        Registrera konto
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        size="medium"
                                        color="success"
                                        sx={{
                                            textTransform: "none",
                                            padding: "10px 20px",
                                            fontSize: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            "&:hover": {
                                                backgroundColor: "#e8f5e9",
                                            },
                                            mt: { xs: 2, md: 1 },
                                            mx: { xs: "auto", md: 0 },
                                        }}
                                        onClick={() => window.location.href = '/home'}
                                    >
                                        Fortsätt som gäst
                                    </Button>
                                </Box>

                                       <Typography variant="body2" sx={{ mt: 1, textAlign: "left" }}>
                                           Har du redan ett konto?{" "}
                                       <Link to="/signin" style={{ color: "#2E7D33", textDecoration: "underline" }}>
                                        Logga in
                                    </Link>
                                </Typography>
                            </div>
                        </Box>
                    </Box>
                </Container>
            </div>

            {/* Footer */}
            <footer
                style={{
                    backgroundColor: "#333",
                    color: "white",
                    padding: "1rem 0",
                    textAlign: "center",
                    position: "relative",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1000,
                    marginTop: "auto",
                    ...(window.innerWidth <= 375 && {position: "relative", bottom: "10px"}),
                }}
            >
                <Typography variant="body2">&copy; {new Date().getFullYear()} Naturborgarmärket</Typography>
            </footer>

        </div>
    );
}


export default WelcomePage;
