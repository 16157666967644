import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import generalPOST from "../http/generalPOST";

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const [params] = new URLSearchParams(useLocation().search);
    const [payload, setPayload] = useState("");
    const history = useHistory();

    const HandleSubmit = async (event) => {
        event.preventDefault();
        let promise;
        let response;

       promise = generalPOST(`${process.env.REACT_APP_API_URL}/reset_password`, { password: newPassword, token: params[0] });

        promise
            .then((res) => {
                response = res;
                return res.text();
            })
            .then((data) => {
                if (response.status === 200 || response.status === 400) {
                    setPayload(data);
                }
            })
            .catch((err) => {
                switch (err.name) {
                    case "AbortError":
                        console.log("post aborted");
                        break;
                    default:
                        break;
                }
            });
    };

    return (
        <Grid container component="main" sx={{ height: "100vh" }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: "url(https://source.unsplash.com/random/?falsterbo)",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
            <Grid item xs={12} sm={8} md={5} elevation={6}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        mt: 13,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{ color: "text.primary", mb: 2 }}>
                        Ändra lösenord
                    </Typography>

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Nytt lösenord"
                        type="password"
                        id="password"
                        onChange={(e) => setNewPassword(e.target.value)}
                        sx={{
                            borderRadius: 1,
                            backgroundColor: "white",
                        }}
                    />

                    <Box>
                        {payload && (
                            <Box sx={{ m: 2 }}>
                                <Typography align="center">{payload}</Typography>
                            </Box>
                        )}
                        {
                            <Button
                                type="return"
                                fullWidth
                                variant="contained"
                                onClick={() => history.push("/SignIn")}
                                sx={{ mt: 3, mb: 2, color: "white", bgcolor: "secondary.main", "&:hover": { bgcolor: "secondary.dark" } }}
                            >
                                Tillbaka
                            </Button>
                        }

                        {!payload && (
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={(e) => HandleSubmit(e)}
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    color: "white",
                                    bgcolor: "primary.main",
                                    "&:hover": { bgcolor: "primary.dark" },
                                }}
                            >
                                Ändra
                            </Button>
                        )}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ResetPassword;
