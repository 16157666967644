import { GoogleMap, useLoadScript, Polygon } from "@react-google-maps/api";
import { DrawingManager } from "@react-google-maps/api";
import React, { useState, useEffect, useCallback } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {getToken} from "../http/tokenGET"; 

const mapContainerStyle = {
    width: "100%",
    height: "542px",
};

const options = {
    disableDefaultUI: true,
    clickableIcons: false,
};

const lib = ["drawing"];

const AdminMap = ({ updatePolygon, clearPolygon, shouldRender, render, polygon, center, selectedArea }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: lib,
    });
    const [drawingManager, setDrawingManager] = useState(null);
    const [userPolygon, setUserPolygon] = useState(null); // Polygon created by user in drawing manager

    const toggleDrawingControls = useCallback((onOff) => {
        switch (onOff) {
            case true:
                drawingManager.setOptions({
                    drawingMode: "polygon",
                    drawingControl: true,
                    drawingControlOptions: {
                        drawingModes: ["polygon"],
                        position: window.google.maps.ControlPosition.TOP_CENTER,
                    },
                });
                break;
            case false:
                drawingManager.setOptions({
                    drawingMode: null,
                    drawingControl: false,
                    drawingControlOptions: {
                        drawingModes: [],
                        position: window.google.maps.ControlPosition.TOP_CENTER,
                    },
                });
                break;
            default:
                break;
        }
    }, [drawingManager]);

    useEffect(() => {
        if (render && userPolygon !== null) {
            userPolygon.setPaths([]);
            toggleDrawingControls(true);
        }
    }, [render, toggleDrawingControls, userPolygon]);

    const onMapLoad = (map) => {
        if (map.getCenter() === null) {
            map.setCenter({ lat: 55.59, lng: 13.02, order: -1 });
        }
    };

    const onDrawingLoad = (drawingManager) => {
        setDrawingManager(drawingManager);
        drawingManager.setOptions({
            drawingControlOptions: {
                drawingModes: ["polygon"],
                position: window.google.maps.ControlPosition.TOP_CENTER,
            },
        });
    };

    const onPolygonComplete = (poly) => {
        poly.setEditable(true);
        poly.setDraggable(true);
        setUserPolygon(poly);
        shouldRender(false); // Vi ska inte ändra på kartan just nu.
        toggleDrawingControls(false);
    };

    const onClearPolygon = () => {
        if (userPolygon !== null) userPolygon.setPaths([]);
        shouldRender(true);
        clearPolygon();
        toggleDrawingControls(true);
    }

    const handleRemove = async () => {
        try {
            const token = await getToken();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/pins/${selectedArea}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    'X-CSRF-TOKEN': token
                },
                credentials: 'include'
            });

            if (response.ok) {
                alert("Pins removed successfully! Updating page...");
                window.location.reload();
            } else if (response.status === 400){
                alert("There exists no pins for this area in the database!");
            } else {
                alert("Failed to delete pins. Please try again.");
                console.error("Error:", response.statusText);
            }
        } catch (error) {
            console.error("Request failed:", error);
            alert("An error occurred while deleting pins.");
        }
    };

    const handleSave = async () => {
        if (userPolygon && userPolygon.getPath().getLength() > 0) {
            const retArr = [];
            const coordinates = userPolygon.getPath().getArray();
            for (let i = 0; i < coordinates.length; i++) {
                retArr.push({
                    lat: coordinates[i].lat(),
                    lng: coordinates[i].lng(),
                    order: i,
                });
            }
            updatePolygon(retArr);

            const payload = {
                area: selectedArea,
                pins: retArr,
            };

            try {
                const token = await getToken();
                const response = await fetch(`${process.env.REACT_APP_API_URL}/pins/${selectedArea}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'X-CSRF-TOKEN': token
                    },
                    credentials: 'include',
                    body: JSON.stringify(payload),
                });

                if (response.ok) {
                    alert("Pins saved successfully! Updating page...");
                    window.location.reload();
                } else if (response.status === 400){
                    alert("There already exists pins for this area in the database!");
                    onClearPolygon();
                } else {
                    alert("Failed to save pins. Please try again.");
                    console.error("Error:", response.statusText);
                }
            } catch (error) {
                console.error("Request failed:", error);
                alert("An error occurred while saving pins.");
            }
        } else {
            alert("No pins found to save.");
        }
    };

    if (loadError) return "Error loading maps";
    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div className="admin-map">
            <Stack sx={{ pt: 3, width: 1, mb: 2 }} direction="row" spacing={2} justifyContent="center">
                <Button onClick={onClearPolygon} variant="outlined">
                    Nollställ
                </Button>
                <Button onClick={handleRemove} variant="outlined">
                    Radera befintliga koordinater
                </Button>
                <Button onClick={handleSave} variant="outlined">
                    Spara
                </Button>
            </Stack>

            <GoogleMap
                onLoad={onMapLoad}
                zoom={13}
                center={center}
                options={options}
                mapContainerStyle={mapContainerStyle}
            >
                {render && <Polygon paths={polygon}></Polygon>}
                <DrawingManager onLoad={onDrawingLoad} onPolygonComplete={onPolygonComplete}></DrawingManager>
            </GoogleMap>
        </div>
    );
};

export default AdminMap;
