import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import generalPOST from "../http/generalPOST";

/**
 * React component used for managing the "Forgot password" functionality.
 * Accessed from the SignIn page when a user presses "Glömt lösenord?".
 * @returns {JSX.Element}
 * @constructor
 */
const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [payload, setPayload] = useState("");
    const history = useHistory();


    const HandleSubmit = async (event) => {
        event.preventDefault();
        let promise = null;

        let response;

        promise = generalPOST(`${process.env.REACT_APP_API_URL}/forgot_password`, { email: email });

        promise
            .then((res) => {
                response = res;
                if (!res.ok) {
                    res.text().then((x) => console.log(x));
                }
                return res.text();
            })
            .then((data) => {
                if (response.status === 200) {
                    setPayload(data);
                }
            })
            .catch((err) => {
                switch (err.name) {
                    case "AbortError":
                        console.log("post aborted in ForgotPassword");
                        break;
                    default:
                        console.error(err.message);
                        break;
                }
            });
    };

    return (
        <Grid container component="main" sx={{ height: "100vh" }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: "url(https://source.unsplash.com/random/?falsterbo)",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
            <Grid item xs={12} sm={8} md={5} elevation={6}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        mt: 13,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "#698681" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{ color: "text.primary", mb: 2 }}>
                        Glömt lösenord
                    </Typography>

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="email"
                        label="E-postadress"
                        type="text"
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            borderRadius: 1,
                            backgroundColor: "white",
                        }}
                    />

                    <Box>
                        {payload && (
                            <Box sx={{ m: 2 }}>
                                <Typography align="center">{payload}</Typography>
                            </Box>
                        )}
                        {
                            <Button
                                type="return"
                                fullWidth
                                variant="contained"
                                onClick={(e) => history.push("/SignIn")}
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    color: "white",
                                    bgcolor: "#698681",
                                    "&:hover": { bgcolor: "#4a5e5b" },
                                }}
                            >
                                Tillbaka
                            </Button>
                        }

                        {!payload && (
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={(e) => HandleSubmit(e)}
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    color: "white",
                                    bgcolor: "#698681",
                                    "&:hover": { bgcolor: "#4a5e5b" },
                                }}
                            >
                                Skicka
                            </Button>
                        )}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ForgotPassword;