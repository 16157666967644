import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";

export default function SignOut() {
    const history = useHistory();
    const [cookies, , removeCookie] = useCookies();

    // Rensa alla cookies när komponenten laddas
    useEffect(() => {
        Object.keys(cookies).forEach((cookieName) => {
            removeCookie(cookieName, { path: "/" });
        });
    }, [cookies, removeCookie]);

    function onClick() {
        history.push("/");
    }

    return (
        <Grid container component="main" sx={{ height: "100vh" }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: "url(https://source.unsplash.com/random/?falsterbo)",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            />
            <Grid item xs={12} sm={8} md={5} elevation={6}>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        mt: 13,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{ color: "text.primary" }}>
                        Du är utloggad
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>
                        Klicka på knappen nedan för att gå tillbaka till startsidan.
                    </Typography>
                    <Button
                        onClick={onClick}
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            bgcolor: "primary.main",
                            color: "white",
                            "&:hover": {
                                bgcolor: "primary.dark",
                            },
                        }}
                    >
                        Till startsidan
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}
