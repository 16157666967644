import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

/**
 * Temporary React component that has been used to display an error message to the user
 * when he tries to access a page that should require the ADMIN role to view.
 * @param info
 * @returns {JSX.Element}
 * @constructor
 */
export default function AccessDenied({ info }) {
    const history = useHistory();
    const theme = useTheme();

    return (
        <div align="center" sx={{ padding: 3 }}>
            <div>{info}</div>
            <Button
                onClick={history.goBack}
                sx={{
                    marginTop: 2,
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                    "&:hover": {
                        backgroundColor: theme.palette.primary.dark,
                    },
                }}
            >
                Gå tillbaka
            </Button>
        </div>
    );
}
