import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: "#8EA434",
        },
        secondary: {
            main: "#8EA434",
        },
        background: {
            default: "#F8F8F8",
        },
        text: {
            primary: "#202020",
            secondary: "#666666",
        },
    },
    typography: {
        fontFamily: "Roboto, Arial, sans-serif",
        h4: {
            fontWeight: 700,
        },
        body1: {
            fontSize: "1rem",
        },
    },
});

export default defaultTheme;
